import type { ComponentType } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import * as Sentry from "@sentry/astro";
import { useMutation } from "@tanstack/react-query";
import clsx from "clsx";
import { FirebaseError } from "firebase/app";
import { createUserWithEmailAndPassword, getAuth, signInWithEmailAndPassword } from "firebase/auth";
import LogRocket from "logrocket";
import { EyeIcon, EyeOffIcon, LoaderCircle } from "lucide-react";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";

import { evaluateUserCredentials } from "@/services/auth";
import { showError } from "@/services/toasts";
import { authStore } from "@/stores/authstore";
import { withQueryClientProvider } from "@/utils/queryClient";
import { getQueryParam } from "@/utils/url";

const signInFormSchema = z.object({
  email: z.string().email("Please enter a valid email."),
  password: z.string().min(8, "Password must be at least 8 characters."),
});

const signUpFormSchema = signInFormSchema
  .extend({
    confirmPassword: z.string(),
  })
  .refine(data => data.password === data.confirmPassword, {
    message: "Passwords do not match",
  });

interface Props {
  isSignUp?: boolean;
}

export const SignInWithEmailAndPasswordForm: ComponentType<Props> = withQueryClientProvider(({ isSignUp }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<z.infer<typeof signUpFormSchema>>({
    defaultValues: {
      email: getQueryParam("email") ?? "",
      password: "",
      confirmPassword: "",
    },
    resolver: zodResolver(isSignUp ? signUpFormSchema : signInFormSchema),
  });

  const { mutate: onSubmit, isPending } = useMutation({
    mutationFn: async (data: z.infer<typeof signInFormSchema>) => {
      authStore.setKey("loading", true);
      try {
        const result = await createUserWithEmailAndPassword(getAuth(), data.email, data.password);
        await evaluateUserCredentials(result);
      } catch (e) {
        if (e instanceof FirebaseError && e.code === "auth/email-already-in-use") {
          try {
            const result = await signInWithEmailAndPassword(getAuth(), data.email, data.password);
            await evaluateUserCredentials(result);
          } catch (e) {
            if (e instanceof FirebaseError && e.code === "auth/invalid-credential") {
              showError("Invalid email or password.");
            } else if (e instanceof FirebaseError && e.code === "auth/too-many-requests") {
              showError("You have reached the maximum number of retries. Please try again in 1-2 minutes.");
            } else {
              showError(e instanceof FirebaseError ? e.message : "An unknown error occurred");
            }
          }
        } else if (e instanceof FirebaseError) {
          showError(e.message);
        } else {
          Sentry.captureException(e as Error);
          LogRocket.captureException(e as Error);
        }
      } finally {
        authStore.setKey("loading", false);
      }
    },
  });

  useEffect(() => {
    const messages = Object.values(errors)
      .concat(errors.root ?? [])
      .map(it => it.message)
      .filter(it => !!it)
      .join(", ");
    if (messages.length === 0) return;
    showError(messages);
  }, [errors]);

  // Determine button colors based on the current domain.
  const isJlmdecks = window.location.hostname === "jlmdecks.com";
  const buttonBaseColor = isJlmdecks ? "bg-stone-900" : "bg-blue-500";
  const buttonHoverColor = isJlmdecks ? "hover:bg-stone-800" : "hover:bg-blue-600";
  const buttonFocusColor = isJlmdecks ? "focus:bg-stone-800" : "focus:bg-blue-600";

  return (
    <form
      onSubmit={handleSubmit(onSubmit as never)}
      className="flex min-w-[400px] max-w-md flex-col gap-4 rounded-xl bg-white p-6 shadow-lg">
      <h3 className="mb-4 text-2xl font-bold text-gray-800">
        {isSignUp ? "Create your account with a strong password" : "Sign in via Email"}
      </h3>
      <div className="relative mt-2 w-full">
        <Controller
          name="email"
          control={control}
          render={({ field, fieldState: { error } }) => {
            return (
              <input
                type="email"
                name={field.name}
                required
                onChange={field.onChange}
                disabled={isPending}
                value={field.value}
                className={clsx(
                  "peer block w-full rounded-xl p-5 text-base transition-opacity duration-150 ease-out placeholder:text-gray-400 autofill:pb-2 autofill:pt-6 focus:border-blue-600 focus:placeholder-opacity-0 focus:ring-2 focus:ring-blue-600 disabled:pointer-events-none disabled:opacity-50 dark:border-neutral-700 dark:bg-neutral-900 dark:text-neutral-400 dark:focus:ring-neutral-600 [&:not(:placeholder-shown)]:pb-2 [&:not(:placeholder-shown)]:pt-6",
                  { "border-red-500": !!error, "border-gray-300": !error },
                )}
              />
            );
          }}
        />

        <label
          htmlFor="hs-floating-input-email"
          className="pointer-events-none absolute start-0 top-0 h-full origin-[0_0] truncate border border-transparent p-4 text-sm transition duration-100 ease-in-out peer-focus:-translate-y-1.5 peer-focus:translate-x-0.5 peer-focus:scale-90 peer-focus:text-gray-500 peer-disabled:pointer-events-none peer-disabled:opacity-50 peer-[:not(:placeholder-shown)]:-translate-y-1.5 peer-[:not(:placeholder-shown)]:translate-x-0.5 peer-[:not(:placeholder-shown)]:scale-90 peer-[:not(:placeholder-shown)]:text-gray-500 dark:text-neutral-500 dark:peer-focus:text-neutral-500 dark:peer-[:not(:placeholder-shown)]:text-neutral-500">
          Email
        </label>
      </div>
      <div className="relative">
        <Controller
          name="password"
          control={control}
          render={({ field, fieldState: { error } }) => {
            return (
              <>
                <input
                  type={showPassword ? "text" : "password"}
                  name={field.name}
                  required
                  onChange={field.onChange}
                  disabled={isPending}
                  value={field.value}
                  className={clsx(
                    "peer block w-full rounded-xl p-5 text-base transition-opacity duration-150 ease-out placeholder:text-gray-400 autofill:pb-2 autofill:pt-6 focus:border-blue-600 focus:placeholder-opacity-0 focus:ring-2 focus:ring-blue-600 disabled:pointer-events-none disabled:opacity-50 dark:border-neutral-700 dark:bg-neutral-900 dark:text-neutral-400 dark:focus:ring-neutral-600 [&:not(:placeholder-shown)]:pb-2 [&:not(:placeholder-shown)]:pt-6",
                    { "border-red-500": !!error, "border-gray-300": !error },
                  )}
                />
                {field.value.length > 0 && (
                  <button
                    onClick={() => setShowPassword(it => !it)}
                    type="button"
                    className="absolute right-4 top-5 text-gray-500 dark:text-neutral-500">
                    {showPassword ? <EyeIcon size={24} /> : <EyeOffIcon size={24} />}
                  </button>
                )}
              </>
            );
          }}
        />
        <label
          htmlFor="hs-floating-input-passowrd"
          className="pointer-events-none absolute start-0 top-0 h-full origin-[0_0] truncate border border-transparent p-4 text-sm transition duration-100 ease-in-out peer-focus:-translate-y-1.5 peer-focus:translate-x-0.5 peer-focus:scale-90 peer-focus:text-gray-500 peer-disabled:pointer-events-none peer-disabled:opacity-50 peer-[:not(:placeholder-shown)]:-translate-y-1.5 peer-[:not(:placeholder-shown)]:translate-x-0.5 peer-[:not(:placeholder-shown)]:scale-90 peer-[:not(:placeholder-shown)]:text-gray-500 dark:text-neutral-500 dark:peer-focus:text-neutral-500 dark:peer-[:not(:placeholder-shown)]:text-neutral-500">
          Password
        </label>
      </div>
      {isSignUp && (
        <div className="relative">
          <Controller
            name="confirmPassword"
            control={control}
            render={({ field, fieldState: { error } }) => {
              return (
                <>
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    name={field.name}
                    required
                    onChange={field.onChange}
                    disabled={isPending}
                    value={field.value}
                    className={clsx(
                      "peer block w-full rounded-lg p-4 text-sm placeholder:text-transparent autofill:pb-2 autofill:pt-6 focus:border-blue-500 focus:pb-2 focus:pt-6 focus:ring-blue-500 disabled:pointer-events-none disabled:opacity-50 dark:border-neutral-700 dark:bg-neutral-900 dark:text-neutral-400 dark:focus:ring-neutral-600 [&:not(:placeholder-shown)]:pb-2 [&:not(:placeholder-shown)]:pt-6",
                      { "border-red-500": !!error, "border-gray-200": !error },
                    )}
                    placeholder="Confirm Password"
                  />
                  {field.value.length > 0 && (
                    <button
                      onClick={() => setShowConfirmPassword(it => !it)}
                      type="button"
                      className="absolute right-4 top-5 text-gray-500 dark:text-neutral-500">
                      {showConfirmPassword ? <EyeIcon size={24} /> : <EyeOffIcon size={24} />}
                    </button>
                  )}
                </>
              );
            }}
          />
          <label
            htmlFor="hs-floating-input-confirmPassword"
            className="pointer-events-none absolute start-0 top-0 h-full origin-[0_0] truncate border border-transparent p-5 text-base transition duration-150 ease-in-out peer-focus:-translate-y-1.5 peer-focus:translate-x-0.5 peer-focus:scale-90 peer-focus:text-gray-600 peer-disabled:pointer-events-none peer-disabled:opacity-50 peer-[:not(:placeholder-shown)]:-translate-y-1.5 peer-[:not(:placeholder-shown)]:translate-x-0.5 peer-[:not(:placeholder-shown)]:scale-90 peer-[:not(:placeholder-shown)]:text-gray-600 dark:text-neutral-500 dark:peer-focus:text-neutral-500 dark:peer-[:not(:placeholder-shown)]:text-neutral-500">
            Confirm Password
          </label>
        </div>
      )}
      <button
        type="submit"
        disabled={isPending}
        className={clsx(
          "inline-flex items-center justify-center gap-x-3 rounded-xl border px-6 py-4 text-base font-semibold text-white shadow focus:outline-none disabled:pointer-events-none disabled:opacity-50",
          buttonBaseColor,
          buttonHoverColor,
          buttonFocusColor,
        )}>
        {isPending && <LoaderCircle size={24} className="animate-spin" />}
        <span>{isSignUp ? "Sign Up" : "Sign In"}</span>
      </button>
      {isSignUp ? (
        <p className="text-right text-sm text-gray-500">
          Already have an account?{" "}
          <a className="underline hover:text-gray-700" href="/">
            Sign in instead
          </a>
        </p>
      ) : (
        <div className="flex justify-center">
          <p className="text-center text-xs text-slate-400">
            Can&apos;t remember?{" "}
            <a className="underline hover:text-slate-700" href="/reset-password">
              Reset password
            </a>
          </p>
        </div>
      )}
    </form>
  );
});
